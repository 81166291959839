import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MonthInformation from 'components/ui/calendar/calendar/MonthInformation';
import { getDateStatuses } from 'utils/UsersService';
import { List, Typography, Grid } from '@mui/material';
import "./PeriodInfo.scss";
import { HubConnectionBuilder } from '@microsoft/signalr'
import TerritoryStatus from "../calendar/status/TerritoryStatus";
import { featureEnabled } from 'utils/FeatureTogglerService';
import { AUTO_MATCH, SHOW_CALENDAR } from 'constants/Features';
import { getHubPath, getTenantPrefix } from 'utils/ProxyPathService';
import i18next from 'i18next';
import BusinessParking from './payment/BusinessParking';
import { getBusinessPayment, confirmBusinessPayment } from 'utils/payments/BusinessPaymentService';

class PeriodInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: [],
      hubConnection: null,
      defaultDate: null,
      index: 0,
      periods: [],
      showCalendar: [],
      autoMatchFeatureEnabled: false,
      show_calendarEnabled: true,
      paymentResponse: null,
      paymentCompleted: false,
      paymentError: false,
      paymentData: null,
      businessPaymentData: null
    };
    this.timer = {};
  }

  componentDidMount = async () => {
    await this.getPaymentStatus();
    const hubConnection = new HubConnectionBuilder()
      .withUrl(getHubPath()).withAutomaticReconnect()
      .build();

    this.setState({ hubConnection }, () => {
      this.state.hubConnection.start()
        .then(() => console.log('Connection started!'))
        .catch(err => console.log('Error while establishing connection :('));

      this.state.hubConnection.on("ReceiveMessage", data => {
        if (data === "dashboard") {
          this.fetchDateStatuses(this.props.currentUserId);
        }
      });
    });

    const autoMatch = await featureEnabled(AUTO_MATCH);
    this.setState({ autoMatchFeatureEnabled: autoMatch });

    const showCalendar = await featureEnabled(SHOW_CALENDAR);
    this.setState({ show_calendarEnabled: showCalendar });

    await this.fetchDateStatuses(this.props.currentUserId);
    await this.getPaymentData(this.props.currentUserId);
  }

  componentWillUnmount = async () => {

    if (this.state.hubConnection) {
      this.state.hubConnection.stop();
    }
  }

  fetchDateStatuses = async (userId) => {
    try {
      const resp = await getDateStatuses(userId);
      if (resp) {
        const showCalendar = resp.map((x) => { return false });
        this.setState({ info: resp, showCalendar });
        if (this.state.info.length > 0) {
          if (this.state.info[0].ownerSpaces && this.state.info[0].ownerSpaces.length > 0) {
            const periods = this.state.info[0].periods.filter(x => x.parkingSpaceId === this.state.info[0].ownerSpaces[0].ownerSpaceId);
            this.setState({ periods: periods })
          } else {
            this.setState({ periods: this.state.info[0].periods })
          }
        }
      }

    } catch (e) {
      console.error(e)
    }
  }

  getMonth = async (date) => {

    this.setState({ defaultDate: date });
  }

  changeIndex = async (rise) => {
    let newIndex = 0;
    let stateIndex = this.state.index;
    if (rise === true) {

      newIndex = stateIndex + 1;
      if (this.state.info[0].ownerSpaces && this.state.info[0].ownerSpaces.length > newIndex) {
        this.setState({ index: newIndex });
        stateIndex = newIndex;
      }
    } else {
      newIndex = stateIndex - 1;
      if (newIndex > -1) {
        this.setState({ index: newIndex });
        stateIndex = newIndex;
      }
    }
    const periods = this.state.info[0].periods.filter(x => x.parkingSpaceId === this.state.info[0].ownerSpaces[stateIndex].ownerSpaceId);
    this.setState({ periods: periods })
  }

  showCalendarF = (item) => {
    let showCalendar_ = this.state.showCalendar;
    const show = showCalendar_[item];
    showCalendar_[item] = !show;
    this.setState({ showCalendar: showCalendar_ });
  }

  goPayment = () => {
    const tenantId = getTenantPrefix();
    const tenantPrefix = tenantId && tenantId !== '' ? tenantId + '/' : '';

    let paymentData = {
      redirect: `${window.location.origin}/businessPaymentConfirm`,
      webhook: `${window.location.origin}/api/${tenantPrefix}v1/BusinessPayment/confirmPayment`,
      lot: this.state.businessPaymentData.lot,
      payment: this.state.businessPaymentData.payment,
      userId: this.props.currentUser.id,
      bankId: null,
      plateNum: this.state.businessPaymentData.plateNum,
      provider: this.state.businessPaymentData.payment.paymentProvider,
      paymentMethodPreferred: 'bank'
    }

    const state = {
      paymentData: paymentData
    }
    this.props.navigate('bankSelection/payment', state, null)
  }

  getPaymentStatus = async () => {
    if (this.props.businessPaymentsEnable) {
      if (localStorage.getItem('businessPayment')) {
        const businessPayment = JSON.parse(localStorage.getItem('businessPayment'))
        this.setState({ businessPayment });
        const payment = {
          id: businessPayment.paymentId,
          bankStatus: businessPayment.bankStatus,
          cardStatus: businessPayment.cardStatus,
          statusGroup: businessPayment.statusGroup
        }
        const success = payment.statusGroup === 'completed';
        confirmBusinessPayment(payment).then(() => {
          this.setState({ businessPayment: null })
          if (success) {
            this.setState({ paymentCompleted: true })
          }
          else {
            this.setState({ paymentError: true })
            this.timer = setInterval(
              () => { this.setState({ paymentError: false }) },
                () => this.getPaymentData(this.props.currentUser.id),
              5000
            );
          }
        });
      }
      localStorage.removeItem('businessPayment')
    }
  }

  getPaymentData = async (userId) => {
    if (this.props.businessPaymentsEnable && !this.state.paymentResponse) {
      const body = {
        userId: userId
      }
      getBusinessPayment(body).then((businessPaymentData) => {
        if(businessPaymentData){
          this.setState({businessPaymentData, paymentCompleted: businessPaymentData.paymentCompleted})
        }
      })
    }
  }

  render() {

    const currentLng = localStorage.getItem('currentLng');
    const { navigate, clicked, hideBookingFeatureEnabled, inlcudeWeekendsInSharing, subscriptionPeriodLimit, businessPaymentsEnable } = this.props
    const { info, showCalendar, autoMatchFeatureEnabled, show_calendarEnabled, paymentResponse, paymentCompleted, paymentError, businessPaymentData } = this.state;

    const defaultDate = this.state.defaultDate;

    return (

      <div>
        {businessPaymentsEnable && (businessPaymentData || paymentResponse || paymentCompleted || paymentError) && <BusinessParking
          goPayment={() => this.goPayment()}
          paymentResponse={paymentResponse}
          paymentCompleted={paymentCompleted}
          paymentError={paymentError}
          amount={businessPaymentData.payment?.amount ?? 0}
        />}
        <div>
          <Grid item xs padding='20px'>
            <Typography className='h4 darkGrey' color='#656D78'>
              {i18next.t('parkinger.CurrentVacancies')}
            </Typography>
          </Grid>
        </div>
        {info.map((item, ix) => (
          <div key={ix}>
            {item ?
              <List style={{ padding: "0" }}>
                {item.lots.map((el, i) => (
                  <div key={i} >
                    <TerritoryStatus
                      item={el}
                      index={ix}
                      showCalendar={this.showCalendarF}
                      show_calendarEnabled={show_calendarEnabled}
                      inlcudeWeekendsInSharing={inlcudeWeekendsInSharing} />
                  </div>
                ))}
              </List>
              : null}
            {item && showCalendar[ix] &&
              <MonthInformation
                item={item}
                navigate={navigate}
                clicked={clicked}
                currentLng={currentLng}
                getMonth={this.getMonth}
                defaultDate={defaultDate}
                // index={index}
                changeIndex={this.changeIndex}
                periods={item.periods}
                autoMatchFeatureEnabled={autoMatchFeatureEnabled}
                hideBookingFeatureEnabled={hideBookingFeatureEnabled}
                inlcudeWeekendsInSharing={inlcudeWeekendsInSharing}
                subscriptionPeriodLimit={subscriptionPeriodLimit}
              ></MonthInformation>
            }
          </div>
        ))}
      </div>
    )
  }
}
export default withTranslation()(PeriodInfo);